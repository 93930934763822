<template>
  <h2 :class="headerClass">
    OpenSnow Features
  </h2>
  <div class="tw-mb-14">
    <OpenSnowFeatureCard />
  </div>
</template>

<script>
export default {
  name: 'OpenSnowFeatures',

  props: {
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
  },
};
</script>
