<template>
  <Card
    :has-body-padding="true"
  >
    <template #body>
      <h3
        v-if="!!title"
        class="tw-text-2xl tw-mb-1 tw-font-medium tw-text-center"
      >
        {{ title }}
      </h3>
      <h4
        v-if="subTitle"
        class="tw-text-xl tw-mb-0.5 tw-font-normal tw-text-center text-light-color"
      >
        {{ subTitle }}
      </h4>
      <small
        :class="[
          'tw-text-center tw-font-normal',
          viewExamples ? 'tw-block' : 'tw-hidden',
        ]"
      >
        Tap each feature to view an example.
      </small>
      <ul
        :class="[
          'tw-text-sm lg:tw-text-base tw-mt-4',
          wrapFeatures ? 'md:tw-flex md:tw-flex-wrap' : null,
        ]"
      >
        <li
          v-for="feature in features"
          :key="feature.text"
          :class="[
            'tw-flex',
            'tw-my-3',
            'tw-font-normal',
            isRestricted(feature) ? 'text-light-color' : null,
            wrapFeatures ? 'md:tw-w-1/3' : null,
          ]"
        >
          <font-awesome-icon
            :icon="getStatusIcon(feature.status)"
            class="tw-mx-2 tw-mt-1"
          />
          <a
            v-if="feature.image"
            class="tw-underline"
            :href="feature.image"
            @click.prevent="showImage(feature.image)"
          >
            {{ feature.text }}
          </a>
          <span
            v-else
          >
            {{ feature.text }}
          </span>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script>
import { featureStatuses } from '@@/components/FAQs/FaqUtils';
import { showImage } from '@@/utils/CommonUtils';

export default {
  name: 'FeatureCard',

  props: {
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    viewExamples: {
      type: Boolean,
      default: false,
    },
    features: {
      type: Array,
      required: true,
      validator(features) {
        const statuses = Object.values(featureStatuses);
        return features
          .every((feature) => statuses.includes(feature.status) && typeof feature.text === 'string');
      },
    },
    wrapFeatures: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getStatusIcon(featureStatus) {
      if (featureStatus === featureStatuses.available) {
        return 'check-circle';
      }

      if (featureStatus === featureStatuses.limited) {
        return 'minus-circle';
      }

      return 'times-circle';
    },

    isRestricted(feature) {
      return feature.status === featureStatuses.restricted;
    },

    showImage(image) {
      showImage(image);
    },
  },
};
</script>
