<template>
  <FeatureCard
    v-bind="featureCardProps"
  />
</template>

<script>
import { getOpenSnowFeatures } from '@@/components/FAQs/FaqUtils';
import PricingPlanMixin from '@@/components/FAQs/PricingPlanMixin';

export default {
  name: 'OpenSnowFeatureCard',

  mixins: [PricingPlanMixin],

  computed: {
    featureCardProps() {
      return {
        viewExamples: false,
        features: getOpenSnowFeatures(),
        wrapFeatures: true,
      };
    },
  },
};
</script>
