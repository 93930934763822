<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addQueryParamsToPath } from '@@/utils/CommonUtils';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';
import { useUserStore } from '@@/stores/User';

const props = defineProps({
  buyNowButtonType: {
    default: null,
    required: false,
    type: String,
    validator(value) {
      return Object.values(ButtonTypes).includes(value);
    },
  },
});

const { registerButtonText } = useDefaultTrial();
const userStore = useUserStore();
const isFree = computed(() => userStore.isFree);
const isGuest = computed(() => userStore.isGuest);

const buyNowButtonProps = computed(() => {
  const to = addQueryParamsToPath('/buy', { source: 'all_access_faq' });
  let type = isGuest.value ? ButtonTypes.primary : ButtonTypes.allAccess;
  type = props.buyNowButtonType ?? type;

  return {
    large: true,
    to,
    type,
  };
});

const freeTrialButtonProps = computed(() => {
  const to = isGuest.value
    ? '/user/register?source=all_access_faq'
    : '/user/settings/start-trial?source=all_access_faq';

  return {
    additionalClassNames: 'tw-whitespace-nowrap',
    large: true,
    to,
    type: ButtonTypes.allAccess,
  };
});
</script>

<template>
  <div
    v-if="isFree"
    class="tw-flex tw-items-stretch"
  >
    <Button
      v-if="isGuest"
      v-bind="freeTrialButtonProps"
      additional-class-names="tw-mr-3 tw-w-full"
    >
      <span class="tw-block tw-text-lg tw-font-normal">
        {{ registerButtonText }}
      </span>
      <small class="tw-block tw--mt-1 tw-font-normal tw-text-xs">
        No credit card required
      </small>
    </Button>
    <Button
      v-bind="buyNowButtonProps"
      additional-class-names="tw-w-full tw-inline-block tw-py-3"
    >
      <span class="tw-flex tw-h-full tw-items-center tw-justify-center tw-text-lg">
        Buy Now
      </span>
    </Button>
  </div>
</template>
