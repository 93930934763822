import { useMetaStore } from '@@/stores/Meta';

export const usePricingPlan = () => {
  const metaStore = useMetaStore();
  const pricingPlans = computed(() => metaStore.getPricingPlans);

  const getValueFromPlan = (slug, key, defaultValue) => {
    return pricingPlans.value?.[slug]?.[key] || defaultValue;
  };

  const getPlanMediumName = (slug, key, defaultValue) => {
    const mediumName = getValueFromPlan(slug, key, defaultValue);
    return mediumName.replace('OpenSnow ', '');
  };

  const groupPlanCurrency = computed(() => getValueFromPlan('group', 'price_currency', 'USD'));
  const groupPlanDays = computed(() => getValueFromPlan('group', 'days', 365));
  const groupPlanMaxMembers = computed(() => getValueFromPlan('group', 'max_members', 4));
  const groupPlanName = computed(() => getValueFromPlan('group', 'name', 'All-Access Group'));
  const groupPlanNameMedium = computed(() => getPlanMediumName('group', 'name', 'All-Access Group'));
  const groupPlanNameShort = computed(() => getValueFromPlan('group', 'name_short', 'Group'));
  const groupPlanPeriod = computed(() => getValueFromPlan('group', 'period', 'per year'));
  const groupPlanPrice = computed(() => getValueFromPlan('group', 'price', 49.99));

  const singlePlanCurrency = computed(() => getValueFromPlan('single', 'price_currency', 'USD'));
  const singlePlanDays = computed(() => getValueFromPlan('single', 'days', 365));
  const singlePlanMaxMembers = computed(() => getValueFromPlan('single', 'max_members', 1));
  const singlePlanName = computed(() => getValueFromPlan('single', 'name', 'All-Access Single'));
  const singlePlanNameMedium = computed(() => getPlanMediumName('single', 'name', 'All-Access Single'));
  const singlePlanNameShort = computed(() => getValueFromPlan('single', 'name_short', 'Single'));
  const singlePlanPeriod = computed(() => getValueFromPlan('single', 'period', 'per year'));
  const singlePlanPrice = computed(() => getValueFromPlan('single', 'price', 34.99));

  const tripPlanCurrency = computed(() => getValueFromPlan('trip', 'price_currency', 'USD'));
  const tripPlanDays = computed(() => getValueFromPlan('trip', 'days', 14));
  const tripPlanMaxMembers = computed(() => getValueFromPlan('trip', 'max_members', 4));
  const tripPlanName = computed(() => getValueFromPlan('trip', 'name', 'All-Access Trip'));
  const tripPlanNameMedium = computed(() => getPlanMediumName('trip', 'name', 'All-Access Trip'));
  const tripPlanNameShort = computed(() => getValueFromPlan('trip', 'name_short', 'Trip'));
  const tripPlanPeriod = computed(() => getValueFromPlan('trip', 'period', 'one-time'));
  const tripPlanPrice = computed(() => getValueFromPlan('trip', 'price', 19.99));

  return {
    groupPlanCurrency,
    groupPlanDays,
    groupPlanMaxMembers,
    groupPlanName,
    groupPlanNameMedium,
    groupPlanNameShort,
    groupPlanPeriod,
    groupPlanPrice,

    singlePlanCurrency,
    singlePlanDays,
    singlePlanMaxMembers,
    singlePlanName,
    singlePlanNameMedium,
    singlePlanNameShort,
    singlePlanPeriod,
    singlePlanPrice,

    tripPlanCurrency,
    tripPlanDays,
    tripPlanMaxMembers,
    tripPlanName,
    tripPlanNameMedium,
    tripPlanNameShort,
    tripPlanPeriod,
    tripPlanPrice,
  };
};
