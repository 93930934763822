<script setup>
import { usePricingPlan } from '@@/composables/usePricingPlan';

defineProps({
  hasBodyPadding: {
    type: Boolean,
    default: true,
  },
  headerClass: {
    type: String,
    default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
  },
  headerText: {
    type: String,
    default: 'Frequently Asked Questions',
  },
  noBodyStyles: {
    type: Boolean,
    default: false,
  },
});

const {
  groupPlanCurrency,
  groupPlanDays,
  groupPlanMaxMembers,
  groupPlanNameMedium,
  groupPlanNameShort,
  groupPlanPrice,
  singlePlanCurrency,
  singlePlanMaxMembers,
  singlePlanNameMedium,
  singlePlanNameShort,
  singlePlanPrice,
  tripPlanCurrency,
  tripPlanDays,
  tripPlanMaxMembers,
  tripPlanNameMedium,
  tripPlanNameShort,
  tripPlanPrice,
} = usePricingPlan();

const faqs = computed(() => [
  {
    question: 'How much does OpenSnow cost?',
    answer: [
      `The ${groupPlanNameMedium.value} subscription is available for $${groupPlanPrice.value} ${groupPlanCurrency.value} per year and provides unlimited access for ${groupPlanMaxMembers.value} people.`,
      `The ${singlePlanNameMedium.value} subscription is available for $${singlePlanPrice.value} ${singlePlanCurrency.value} per year and provides unlimited access for ${singlePlanMaxMembers.value} person.`,
      `The ${tripPlanNameMedium.value} plan is available for $${tripPlanPrice.value} ${tripPlanCurrency.value} and provides unlimited access for ${tripPlanMaxMembers.value} people for ${tripPlanDays.value} days (no subscription).`,
    ].join(' '),
  },
  {
    question: 'Is OpenSnow All-Access good for 365 days?',
    answer: `The ${groupPlanNameMedium.value} and ${singlePlanNameShort.value} plans are valid for ${groupPlanDays.value} days to help you plan all of your winter and summer adventures. The ${tripPlanNameMedium.value} plan is only valid for ${tripPlanDays.value} days.`,
  },
  {
    question: 'How does the Group subscription work?',
    answer: `${groupPlanMaxMembers.value} people. $${groupPlanPrice.value} ${groupPlanCurrency.value} per year. The Group subscription allows you to share your All-Access benefits with ${groupPlanMaxMembers.value - 1} friends or family members, with each member having their own login, favorite locations list, and snow alerts. Already have a Single subscription? We will automatically prorate the difference for the time remaining after you upgrade to the Group subscription. Existing paying All-Access subscribers cannot be added to the Group subscription.`,
  },
  {
    question: `How does the ${tripPlanNameShort.value} plan work?`,
    answer: `${tripPlanMaxMembers.value} people. ${tripPlanDays.value} days. $${tripPlanPrice.value} ${tripPlanCurrency.value}. No subscription. The Trip plan allows you to share your All-Access benefits with ${tripPlanMaxMembers.value - 1} friends or family members, with each member having their own login, favorite locations list, and snow alerts. This new ${tripPlanNameShort.value} plan is perfect for tracking the forecast ahead of an upcoming ski trip or summer vacation.`,
  },
  {
    question: 'Will my OpenSnow subscription automatically renew?',
    answer: `Yes, the ${groupPlanNameShort.value} and ${singlePlanNameShort.value} subscriptions are set to automatically renew unless canceled before the end of the ${groupPlanDays.value}-day subscription term. You can easily cancel your subscription via the app or website with one click. The ${tripPlanNameShort.value} plan is not a subscription and will not automatically renew.`,
  },
  {
    question: 'How does the free trial work?',
    answer: 'New accounts will automatically receive the full OpenSnow experience, with no credit card or payment information required. If you choose not to purchase OpenSnow after the free trial is over, you will be automatically downgraded to a free account and not charged. You will still be able to compare snow reports and view weather forecasts.',
  },
  {
    question: 'How do you make your forecasts?',
    answer: 'We update the automated forecasts every hour with a proprietary blend of global and high-resolution model data. Blending multiple global and high-resolution models increases accuracy and confidence in the forecast, among many other benefits. The data can be viewed as hourly forecasts for the next 10 days and daily forecasts for the next 10 days.',
  },
  {
    question: 'What is OpenSnow?',
    answer: 'OpenSnow is a weather forecasting and conditions information service that provides everything that you need for your next outdoor adventure. View 10-day weather forecasts and snow reports for your favorite locations, read expert analysis from local forecasters, track storms with high-resolution 3D weather maps, receive custom snow alerts, and get the latest weather forecast for any location on Earth.',
  },
]);
</script>

<template>
  <div>
    <h2 :class="headerClass">
      {{ headerText }}
    </h2>
    <Card
      :has-body-padding="hasBodyPadding"
      :no-body-styles="noBodyStyles"
    >
      <template #body>
        <ul>
          <li
            v-for="faq in faqs"
            :key="faq.question"
          >
            <h3
              class="tw-text-xl tw-mb-2 tw-font-medium"
            >
              {{ faq.question }}
            </h3>
            <p
              class="tw-mb-6"
            >
              {{ faq.answer }}
            </p>
          </li>
        </ul>
      </template>
    </Card>
  </div>
</template>
