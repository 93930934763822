export const featureStatuses = {
  available: 'available',
  limited: 'limited',
  restricted: 'restricted',
};

export const getOpenSnowFeatures = () => {
  const { available } = featureStatuses;

  return [
    {
      status: available,
      text: '10-Day Snow Forecasts',
      image: '',
    },
    {
      status: available,
      text: '10-Day Hourly Forecasts',
      image: '',
    },
    {
      status: available,
      text: 'Local "Daily Snow" Experts',
      image: '',
    },
    {
      status: available,
      text: '3D Weather Maps',
      image: '',
    },
    {
      status: available,
      text: 'Custom Snow Alerts',
      image: '',
    },
    {
      status: available,
      text: 'Forecasts Anywhere on Earth',
      image: '',
    },
    {
      status: available,
      text: '10-Day Historical Weather',
      image: '',
    },
    {
      status: available,
      text: 'Favorite & Offline Trail Maps',
      image: '',
    },
    {
      status: available,
      text: 'Favorite & Timelapse Cams',
      image: '',
    },
    {
      status: available,
      text: 'Snowpack Graphs',
      image: '',
    },
    {
      status: available,
      text: 'Historical Snow Reports',
      image: '',
    },
    {
      status: available,
      text: 'Season Snowfall',
      image: '',
    },
    // removing this so things balance nicely in 3-columns, can add it back when we have two more features
    // {
    //   status: available,
    //   text: 'Estimated Trail Conditions',
    //   image: '',
    // },
    {
      status: available,
      text: 'Offline Satellite & Terrain Maps',
      image: '',
    },
    {
      status: available,
      text: 'Nearby Weather Stations',
      image: '',
    },
    {
      status: available,
      text: 'Land Ownership Maps',
      image: '',
    },
    {
      status: available,
      text: 'Lightning Risk Map',
      image: '',
    },
    {
      status: available,
      text: 'Fall Colors Map',
      image: '',
    },
    {
      status: available,
      text: 'iOS Widgets',
      image: '',
    },
  ];
};

export const getWeatherAppFeatures = (isOpenSnow = true) => {
  const { available, restricted } = featureStatuses;

  const features = [
    {
      image: '',
      text: 'Hourly Forecasts',
      status: available,
    },
    {
      image: '',
      text: 'Daily Forecasts',
      status: available,
    },
    {
      image: '',
      text: 'Forecasts for Mountains',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Expert Forecasters',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Favorite Ski Resort List',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Day & Night Snow Forecast',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Weather History',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Backcountry Snow Reports',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Proprietary Map Overlays',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Forecast & Report Alerts',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Compare Nearby Ski Resorts',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Compare Season Passes',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Compare Snow Stake Cams',
      status: isOpenSnow ? available : restricted,
    },
    {
      image: '',
      text: 'Offline Trail Maps',
      status: isOpenSnow ? available : restricted,
    },
  ];

  if (!isOpenSnow) {
    // Remove images so links aren't displayed for Other Weather Apps features
    return features.map(({ text, status }) => ({ text, status }));
  }

  return features;
};
